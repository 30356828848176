import data from "./equipmentManagementData.js";

import equipment from "./child/equipment.vue";
import measuringPoint from "./child/measuringPoint.vue";
import imgSign from "./child/imgSign.vue";

import uploadImg from "../../../components/uploadImg.vue";

import setupReport from "../../../components/setupReport.vue"

export default {
	name: "equipmentManagement",
	data,
	created() {
		this.getAuthorityList();
		this.getList();
	},
	mounted() {},
	computed: {},
	methods: {
		// 接口开头 
		// 获取权限列表 cmn
		getAuthorityList() {
			this.axios.get('web2/mang/odnr/cmn').then((res) => {
				this.authorityList = [];
				if (res.status) {
					this.authorityList = res.data;
				}
			})
		},
		// 获取公司项目列表
		getList(data = {}) {
			this.axios.post('web2/mang/odnr/gcpt', data).then((res) => {
				this.listData = [];
				if (res.status) {
					this.listData = res.data;
					if (res.data.length != 0 && !this.revised) {
						$(() => {
							$('.text-box').eq(0).bind("selectText", (e, data) => {
								this.selectText(e, data);
							})
							$('.text-box').eq(0).trigger("selectText", [res.data[0]])
						})
					}
				}
			})
		},
		// 获取设备、测点列表
		getDataList(value = {}) {
			var data = {
				size: this.pagesize,
				page: this.currentPage,
				loading: false,
			};
			// 升序   降序  启停
			// tp:排序标准：（设备编号1，测点编号2，状态3 ，启停4，创建时间5，文件数6）  st:升序1 降序2   启停：全部1 启2 停3 
			if (this.enterprise_info.id != null) {
				data.companyId = this.enterprise_info.id;
			} else if (this.project_info.id != null) {
				data.projectId = this.project_info.id;
			}

			if (JSON.stringify(value) != '{}') {
				for (let key in value) {
					data[key] = value[key];
					if (this.activeIndex == '1') {
						this.equipmentCondition[key] = value[key];
					} else if (this.activeIndex == '2') {
						this.pointCondition[key] = value[key];
					}
				}
			} else {
				if (this.activeIndex == '1') {
					this.equipmentCondition = {};
				} else if (this.activeIndex == '2') {
					this.pointCondition = {};
				}
			}
			// // console.log(value)
			// // console.log(this.equipmentCondition)

			this.axios.post('web2/mang/odnr/gpdlist', data).then((res) => {
				if (JSON.stringify(value) == '{}') {
					this.pointList = [];
					this.equipmentList = [];
				} else {
					if (this.activeIndex == '1') {
						this.equipmentList = [];
					} else if (this.activeIndex == '2') {
						this.pointList = [];
					}
				}

				if (res.status) {
					if (JSON.stringify(value) == '{}') {
						this.pointList = res.data.point;
						this.equipmentList = res.data.device;
					} else {
						if (this.activeIndex == '1') {
							this.equipmentList = res.data.device;
						} else if (this.activeIndex == '2') {
							this.pointList = res.data.point;
						}
					}

					this.deviceTotal = res.data.deviceTotal;
					this.pointTotal = res.data.pointTotal;

					if (this.activeIndex == '1') {
						this.total = this.deviceTotal;
						let len = res.data.device.length;
						if (data.page != 1 && len == 0 && res.data.deviceTotal != 0) {
							this.currentPage = data.page - 1;
							this.getDataList(this.equipmentCondition);
						}
					} else {
						this.total = this.pointTotal;
						let len = res.data.point.length;
						if (data.page != 1 && len == 0 && res.data.pointTotal != 0) {
							this.currentPage = data.page - 1;
							this.getDataList(this.pointCondition);
						}
					}
				}
			})
		},
		// 设备模糊搜索
		equipmentSearch(data = {}) {
			this.axios.post('web2/mang/odnr/gpdlist', data).then((res) => {
				this.equipmentList = [];
				if (res.status) {
					this.equipmentList = res.data.device;
					this.deviceTotal = res.data.deviceTotal;
					this.total = this.deviceTotal;
				}
			})
		},
		// 测点模糊搜索
		pointSearch(data = {}) {
			this.axios.post('web2/mang/odnr/gpdlist', data).then((res) => {
				this.pointList = [];
				if (res.status) {
					this.pointList = res.data.point;
					this.pointTotal = res.data.pointTotal;
					this.total = this.pointTotal;
				}
			})
		},
		// 添加公司
		addCompany() {
			this.axios.post('web2/mang/dmc/adc', this.addEnterprise).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '企业新增成功',
						type: 'success'
					});
					this.getList();
					this.close('add-enterprise');
				}
			})
		},
		// 添加项目 
		addProjectf() {
			this.axios.post('web2/mang/dmd/adpj', this.addProject).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '项目新增成功',
						type: 'success'
					});
					this.getList();
					this.close('add-project');
				}
			})
		},
		// 添加项目获取公司列表
		getCompany() {
			var data = {
				params: {
					loading: false
				}
			};
			this.axios.get('web2/mang/dmd/gcin', data).then((res) => {
				this.allEnterprise = [];
				if (res.status) {
					this.allEnterprise = res.data;
					if (res.data.length == 1) {
						this.addProject.companyId = res.data[0].id;
					}
				}
			})
		},
		// 项目删除
		projectDelete(id, name) {
			this.$confirm('此操作将删除该<strong>' + name + '</strong>下所有数据，是否继续！', '严重警告', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					customClass: 'icon-big',
					cancelButtonClass: 'cancelButton',
					confirmButtonClass: 'confirmButton',
					type: 'error',
					center: true,
					dangerouslyUseHTMLString: true
				})
				.then(() => {
					// dpj
					this.axios.post('web2/mang/dmh/dtpj', {
						id
					}).then((res) => {
						if (res.status) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '删除成功!'
							});
							this.getList()
						}

					})

				})
				.catch(() => {});
		},
		// 企业删除
		enterpriseDelete(id, name) {
			this.$confirm('此操作将删除该<strong>' + name + '</strong>下所有数据，是否继续！', '严重警告', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error',
					customClass: 'icon-big',
					cancelButtonClass: 'cancelButton',
					confirmButtonClass: 'confirmButton',
					center: true,
					dangerouslyUseHTMLString: true
				})
				.then(() => {
					// dc
					this.axios.post('web2/mang/dmg/dcm', {
						id
					}).then((res) => {
						if (res.status) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '删除成功!'
							});
							this.getList()
						}
					})
				})
				.catch(() => {});
		},
		// 修改企业 mc
		modifyEnterprise() {
			var data = {};
			for (let key in this.addEnterprise) {
				if (key != 'person') {
					data[key] = this.addEnterprise[key];
				}
			}
			this.axios.post('web2/mang/dme/mcp', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '企业信息修改成功',
						type: 'success'
					});
					this.revised = true;
					this.close('add-enterprise');
					for (let key in data) {
						this.enterprise_info[key] = data[key];
					}
					try {
						this.enterprisType.forEach((i) => {
							if (i.value == this.enterprise_info.type) {
								this.enterprise_info.typeName = i.label;
								throw ''
							}
						})
					} catch (e) {
						//TODO handle the exception
					}

					this.getList();
				}
			})
		},
		// 修改项目 mpj
		modifyProject() {
			var data = {};
			for (let key in this.project_info) {
				if (key != 'person' && key != 'content') {
					data[key] = this.addProject[key];
				}
			}
			this.axios.post('web2/mang/dmf/mpj', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '项目信息修改成功',
						type: 'success'
					});
					for (let k in this.project_info) {
						this.project_info[k] = this.addProject[k];
					}
					this.revised = true;
					this.close('add-project')
					this.getList();
				}
			})
		},
		// 编辑项目描述
		editDescription() {
			// var data = {
			// 	id: this.describe.id,
			// 	content: this.describe.content
			// };
			this.axios.post('web2/mang/dmf/mpdes', this.describe).then((res) => {
				var status = false;
				if (res.status) {
					// this.$message({
					// 	showClose: true,
					// 	type: 'success',
					// 	message: '编辑成功!'
					// });
					this.project_info.content = this.describe.content;
					for (let key in this.describe) {
						this.describe[key] = null;
					}
					status = true;
				}
				if (this.project_info.pn1 != '') {
					if (status) {
						this.close('add-point');
					}
				} else {
					this.uploadProjectImg(status)
				}
			})
		},
		// 结束项目
		endProject(e) {
			var obj = $(e.currentTarget);
			this.$confirm('请确认<strong>' + this.project_info.name + '</strong>监测工作是否已完成，结束后无法再启动, 是否结束?', '提示', {
				confirmButtonText: '结束',
				cancelButtonText: '取消',
				type: 'warning',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				dangerouslyUseHTMLString: true
			}).then(() => {
				var data = {
					id: this.project_info.id
				};
				// // console.log(data)
				this.axios.post('web2/mang/dmn/fps', data).then((res) => {
					if (res.status) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '结束成功!'
						});
					}
				})

			}).catch(() => {});
		},
		// 企业/项目修改取消
		cancelModify(val) {
			if (val == "企业") {
				for (let key in this.enterprise_backupInfo) {
					this.enterprise_info[key] = this.enterprise_backupInfo[key];
				}
			} else if (val == "项目") {
				for (let key in this.project_backupInfo) {
					this.project_info[key] = this.project_backupInfo[key];
				}
			}
			this.editMode = false;
		},
		// 添加设备
		addEquipments() {
			var data = {
				unit: this.addEquipment.unit,
				deviceType: this.addEquipment.type,
				deviceNum: this.addEquipment.numbering,
				projectId: this.project_info.id,
				pas: this.addEquipment.pas
			};
			if (this.addEquipment.companyId == "" || this.addEquipment.companyId == null) {
				data.companyId = this.enterprise_info.id;
			} else {
				data.companyId = this.addEquipment.companyId;
			}
			this.axios.post('web2/mang/dmk/add', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设备新增成功',
						type: 'success'
					});
					this.close('add-equipment');

					this.getDataList(this.equipmentCondition);
				}
			})
		},
		// 添加测点
		addPoints() {
			var text = this.addPoint.name;
			if (text.toString() == '' || text == 'ZD-') {
				this.$message({
					showClose: true,
					message: '测点名称不能为空',
					type: 'warning'
				});
				return
			}
			var flag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]")
			if (escape(text).indexOf("%u") < 0) {
				// if (flag.test(text)) {
				// 	this.$message({
				// 		showClose: true,
				// 		message: '测点名称不能含有英文符号或特殊符号！',
				// 		type: 'warning'
				// 	});
				// 	return
				// }
				if (text.length > 9) {
					this.$message({
						showClose: true,
						message: '测点名称字符长度不能超过9位！',
						type: 'warning'
					});
					return;
				}
			} else {
				this.$message({
					showClose: true,
					message: '测点名称不能含有中文或中文符号！',
					type: 'warning'
				});
				return
			}
			var data = new FormData();
			// data.append('name', "ZD-" + this.addPoint.name);
			data.append('name', this.addPoint.name);
			data.append('address', this.addPoint.position);
			if (this.project_info.id) {
				data.append('projectId', this.project_info.id);
			}

			if (this.enterprise_info.id) {
				data.append('companyId', this.enterprise_info.id);
			}

			var file = this.$refs.addPointImg.getFile();
			if (file.length != 0) {
				data.append('file', file[0].raw);
			}
			this.axios.post('web2/mang/dmj/adp', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '测点新增成功',
						type: 'success'
					});
					this.close('edit-point');

					this.getDataList(this.pointCondition);
				}
			})
		},
		// 编辑设备
		equipmentEdit() {
			var data = {
				id: this.addEquipment.id,
				deviceNum: this.addEquipment.numbering,
				deviceType: this.addEquipment.type,
				alert: this.addEquipment.alarm,
				unit: this.addEquipment.unit,
				pas: this.addEquipment.pas
			};
			if (this.addEquipment.companyId != "" && this.addEquipment.companyId != null) {
				data.companyId = this.addEquipment.companyId;
			}
			this.axios.post('web2/mang/dml/md', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设备信息修改成功',
						type: 'success'
					});
					this.close('add-equipment');
					this.getDataList(this.equipmentCondition);
				}
			})
		},
		// 编辑测点
		pointEdit() {
			// var text = 'ZD-' + this.addPoint.name;
			let text = this.addPoint.name;
			if (text.toString() == '' || text == 'ZD-') {
				this.$message({
					showClose: true,
					message: '测点名称不能为空',
					type: 'warning'
				});
				return
			}
			// else if (text.indexOf('ZD-') == 0) {
			// 	this.$message({
			// 		showClose: true,
			// 		message: '测点名称必须以ZD-开头',
			// 		type: 'warning'
			// 	});
			// 	return
			// }
			// var flag = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]")
			if (escape(text).indexOf("%u") < 0) {
				// if (flag.test(text)) {
				// 	this.$message({
				// 		showClose: true,
				// 		message: '测点名称不能含有英文符号或特殊符号！',
				// 		type: 'warning'
				// 	});
				// 	return
				// }
				if (text.length > 9) {
					this.$message({
						showClose: true,
						message: '测点名称字符长度不能超过9位！',
						type: 'warning'
					});
					return;
				}
			} else {
				this.$message({
					showClose: true,
					message: '测点名称不能含有中文或中文符号！',
					type: 'warning'
				});
				return
			}
			var data = new FormData();
			data.append('id', this.addPoint.id);
			data.append('name', text);
			if (this.addPoint.position) {
				data.append('address', this.addPoint.position);
			}
			var judge = this.$refs.addPointImg.getJudgeData();
			if (this.addPoint.pn1 == '' || judge) {
				var file = this.$refs.addPointImg.getFile();
				if (file.length != 0) {
					data.append('file', file[0].raw);
				}
			}
			this.axios.post('web2/mang/dmj/mp', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '测点信息修改成功',
						type: 'success'
					});
					this.close('edit-point');
					this.getDataList(this.pointCondition);
				}
			})
		},
		// 设备校准证书编号填写自动填写有效期
		automaticallyFill() {
			var data = this.certificate.serialNumber;
			if (data) {
				this.certificate.years = Number(data.slice(0, 4)) + 1;
				this.certificate.month = data.slice(4, 6);
			}
		},
		// 编辑设备校准证书信息
		editorCertificateInformation() {
			for (let k in this.certificate) {
				if (this.certificate == "") {
					this.$message({
						showClose: true,
						message: '请将信息填写完整',
						type: 'warning'
					});
				}
			}
			var data = {
				id: this.certificate.id,
				ccno: this.certificate.serialNumber,
				tdate: this.certificate.years + "/" + this.certificate.month + "/" + this.certificate.day,
			}
			this.axios.post('web2/mang/dmu/mtm', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '修改成功！',
						type: 'success'
					});
					this.getDataList(this.equipmentCondition);
					this.close('certificate');
				}
			})
		},
		// 上传项目图片（测点布设图） id（项目） file
		uploadProjectImg(status) {
			var file = this.$refs.pointImg.getFile();
			if (file.length == 0) {
				// this.$message({
				// 	showClose: true,
				// 	message: '请先选择图片',
				// 	type: 'warning'
				// });
				if (status) {
					this.close('add-point');
				}
				return;
			}
			var data = new FormData();
			data.append("id", this.project_info.id);
			data.append('file', file[0].raw);
			// // console.log(file)
			this.axios.post('web2/mang/dmv/uplop', data).then((res) => {
				if (res.status) {
					// this.$message({
					// 	showClose: true,
					// 	message: '图片上传成功',
					// 	type: 'success'
					// });
					this.project_info.pn1 = this.constData.imgUrl + "res/static/ppname/1" + res.data;
					this.project_backupInfo.pn1 = this.constData.imgUrl + "res/static/ppname/1" + res.data;
					this.$forceUpdate();
					if (status) {
						this.close('add-point');
					}
					// 刷新测点布设图组件数据
					setTimeout(() => {
						this.$refs.imgSign.setUrl();
					}, 100)
					this.$refs.pointImg.clearFile();
				}
			})
		},
		// 删除测点布设图 
		deleteProjectImg() {
			this.$confirm('此操作将永久删除测点布设图, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.axios.post('web2/mang/dmv/dlpp', {
					id: this.project_info.id
				}).then((res) => {
					if (res.status) {
						this.$message({
							showClose: true,
							message: '删除成功',
							type: 'success'
						});
						this.project_info.pn1 = '';
						this.$forceUpdate();
					}
				});
			}).catch(() => {});
		},
		// 项目设置（测点布设图和项目描述）
		projectSettings() {
			this.editDescription();
		},
		// 全局搜索
		search() {
			var data = {
				company: this.searchContent
			};
			this.getList(data)
		},
		// 模糊查询
		fuzzyQuery() {
			var data = {};
			if (this.enterprise_info.id != null) {
				data.companyId = this.enterprise_info.id;
			} else if (this.project_info.id != null) {
				data.projectId = this.project_info.id;
			}

			if (this.activeIndex == '1') {
				data.device = this.searchCondition;
				this.equipmentSearch(data);
			} else {
				data.point = this.searchCondition;
				this.pointSearch(data);
			}
		},
		// 信息编辑
		infoEdit(val = "") {
			this.editMode = true;
			if (val == '企业') {
				for (let key in this.addEnterprise) {
					this.addEnterprise[key] = this.enterprise_info[key];
				}
				this.open('add-enterprise');
			} else {
				for (let key in this.addProject) {
					this.addProject[key] = this.project_info[key];
				}
				this.open('add-project');
			}
		},
		// 设备/测点选择
		handleSelect(key, keyPath) {
			if (this.status == '') {
				this.$message({
					showClose: true,
					message: '请先选择企业或项目',
					type: 'warning'
				});
				return
			}
			this.activeIndex = keyPath[0];
			// // console.log(this.activeIndex)
			if (keyPath[0] == '1') {
				this.total = this.deviceTotal;
				var len = Math.ceil(this.deviceTotal / this.pagesize);
				if (len < this.currentPage) {
					this.currentPage = len;
					this.getDataList();
				}
			} else {
				this.total = this.pointTotal;
				var len = Math.ceil(this.pointTotal / this.pagesize);
				if (len < this.currentPage) {
					this.currentPage = len;
					this.getDataList();
				}
			}
			$('.pop-ups').hide();
			// // console.log(key, keyPath);
		},
		// 选择企业/项目
		selectText(e, data, companyId = null, parentData = null) {
			this.clearData(this.enterprise_info);
			this.clearData(this.project_info);
			this.editMode = false;
			this.activeIndex = '1';
			this.currentPage = 1;
			this.status = '';
			// 关闭启用弹窗
			$('.pop-ups').hide();
			if (companyId == null) {
				$(e.currentTarget).prev().eq(0).find('.caret').click();
			}
			this.status = $(e.currentTarget).attr('status');
			$('.text').removeClass('active');
			$(e.currentTarget).addClass('active');
			var text = this.status;
			for (let key in data) {
				if (text == '企业') {
					let datas = data[key];
					// if (key == 'pn1' || key == 'pn2' || key == 'pn3') {
					// 	this.enterprise_info['img' + key[2]] = datas;
					// 	this.enterprise_backupInfo['img' + key[2]] = datas;
					// 	if (datas != '') {
					// 		datas = this.constData.imgUrl + "res/static/cmpp/" + datas;
					// 	}
					// }
					if (key != 'children') {
						this.enterprise_info[key] = datas;
						this.enterprise_backupInfo[key] = datas;
						try {
							this.enterprisType.forEach((i) => {
								if (i.value == this.enterprise_info.type) {
									this.enterprise_info.typeName = i.label;
									throw ''
								}
							})
						} catch (e) {
							//TODO handle the exception
						}
					}
				} else if (text == '项目') {
					if (key != 'pn1') {
						this.project_info[key] = data[key];
						this.project_backupInfo[key] = data[key];
					} else {
						if (data[key] != '') {
							this.project_info[key] = this.constData.imgUrl + "res/static/ppname/1" + data[key];
							this.project_backupInfo[key] = this.constData.imgUrl + "res/static/ppname/1" + data[key];
						} else {
							this.project_info[key] = data[key];
							this.project_backupInfo[key] = data[key];
						}
					}
				}
			}
			if (text == '项目') {
				this.project_info.companyId = companyId;
				this.project_backupInfo.companyId = companyId;
				if (parentData != null) {
					this.project_parent_info = parentData;
				}
			}
			setTimeout(() => {
				this.$refs.equipment.startAndStop = 2;
				this.$refs.measuringPoint.startAndStop = 2;
				this.getDataList();
			}, 200)
		},
		//报告设置参数获取
		getSetup(val) {
			var data = {}

			if (val == 1) {
				data.companyId = this.enterprise_info.id;
			} else if (val == 2) {
				data.projectId = this.project_info.id;
			}
			this.axios.post('web2/mang/dmt/grpcf', data).then((res) => {
				var value = {};
				for (let k in res.data) {
					value[k] = res.data[k];
				}
				if (val == 2) {
					if (JSON.stringify(this.project_parent_info) != "{}" && this.project_parent_info
						.hasOwnProperty('name')) {
						value.cname = this.project_parent_info.name;
					} else {
						value.cname = '';
					}
					if (JSON.stringify(this.project_info) != "{}" && this.project_info
						.hasOwnProperty('name')) {
						value.projectName = this.project_info.name;
					} else {
						value.projectName = '';
					}
					if (JSON.stringify(this.project_info) != "{}" && this.project_info
						.hasOwnProperty('address')) {
						value.projectAddress = this.project_info.address;
					} else {
						value.projectAddress = '';
					}
				}
				this.$refs.setupReport.open(value, data)
			})
			// 
		},
		// 获取报告图片
		getUploadImg() {
			var obj = {
				a: 3,
				b: 3,
				c: 7,
			}
			for (let k in this.reportFigure) {
				this.reportFigure[k] = '';
			}
			const loadingObj = this.$loading({
				target: '.animationContainer',
				lock: true,
				text: '图片加载中',
				spinner: 'el-icon-loading',
				fullscreen: true,
				background: 'rgba(0,0,0,0.2)'
			});
			for (let k in obj) {
				for (let i = 1; i <= obj[k]; i++) {
					let name = k + i;
					let url = 'web2/mang/dmo/cmpp/' + name;
					this.axios.get(url, {
						params: {
							id: this.enterprise_info.id,
							loading: false,
							responseType: 'arraybuffer'
						}
					}).then((res) => {
						const data = res;
						var href = ''
						if (data.byteLength > 0) {
							// data 是返回来的二进制数据
							var blob = new Blob([data]);
							href = window.URL.createObjectURL(blob);
							if (href) {
								if (k == 'a') {
									this.$refs['file' + i].setFileData(href);
								} else if (k == 'b') {
									this.$refs['file' + (Number(i) + Number(obj.a))].setFileData(href);
								} else if (k == 'c') {
									this.$refs['file' + (Number(i) + Number(obj.a) + Number(obj.b))]
										.setFileData(href);
								}
								// this.reportFigure[name] = href;
								this.$set(this.reportFigure, name, href)
							}
						}
						if (name == 'c7') {
							setTimeout(() => {
								loadingObj.close();
							}, 300)
						}
					})
				}
			}

		},
		// 获取文件上传数据
		getimglist(val) {
			var data = new FormData();
			var allowedToUpload = false;
			for (let i = 1; i < 14; i++) {
				var uploadObj = this.$refs['file' + i];
				let uploadInfo = uploadObj.getConfiguration();
				let file = uploadObj.getFile();
				if (uploadObj.getJudgeData() || this.reportFigure[uploadInfo.name] == '') {
					if (file.length > 0) {
						data.append(uploadInfo.name, file[0].raw);
						if (!allowedToUpload) {
							allowedToUpload = true;
						}
					}
				}
			}
			// // console.log(data);
			data.append('id', this.enterprise_info.id);
			if (!allowedToUpload) {
				this.$message({
					showClose: true,
					message: '请先选择或更改图片，再进行上传',
					type: 'warning'
				});
				return;
			}
			this.imgsUpload(data);
		},
		// 上传报告图片
		imgsUpload(data, fileInfo) {
			this.axios.post('web2/mang/dmo/upcp', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '图片上传成功',
						type: 'success'
					});
					// // console.log(this.enterprise_info)
					this.revised = true;
					this.close('enterprise-pop-ups')
				}
			});
		},
		// 删除图片
		imgDelete(num) {
			var data = {
				id: this.enterprise_info.id,
				name: num,
			}
			this.axios.post('web2/mang/dmo/dcp', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '图片删除成功',
						type: 'success'
					});
					this.reportFigure[num] = "";
				}
			})
		},
		// 创建地图
		setMap(data, obj, val) {
			this.selectPoint = '';
			this.map = new BMap.Map(obj);
			var point = data.split(',');
			var points = null;
			if (obj == 'view-map') {
				this.open('map-pop-ups');
				setTimeout(() => {
					if (point.length == 2) {
						points = new BMap.Point(Number(point[0]), Number(point[1]));
						this.map.centerAndZoom(points, 8);
						this.map.enableScrollWheelZoom(true);
						this.setViewMap(points);
					} else {
						this.$message({
							showClose: true,
							message: '暂无当前位置信息！',
							type: 'error'
						});
					}
				}, 200)
			} else if (obj == 'pick-point') {
				setTimeout(() => {
					if (point.length == 2) {
						points = new BMap.Point(Number(point[0]), Number(point[1]));
						this.selectPoint = data;
						this.map.centerAndZoom(points, 8);
						this.map.enableScrollWheelZoom(true);
						this.setViewMap(points);
					} else {
						this.map.centerAndZoom(new BMap.Point(104.065735, 30.659462), 8);
						this.map.enableScrollWheelZoom(true);
					}
				}, 200)
				this.open('pick-point');
				this.setPoint(val);
			}

		},
		// 地图标点
		setViewMap(data) {
			// // console.log(data)
			var allOverlay = this.map.getOverlays();
			for (var i = 0; i <= allOverlay.length - 1; i++) {
				if (allOverlay[i].hasOwnProperty("markId")) {
					this.map.removeOverlay(allOverlay[i]);
				}
			}
			var i = require("../../../assets/images/bicon.png");
			var myIcon = new BMap.Icon(i, new BMap.Size(40, 40), {
				imageSize: new BMap.Size(40, 40)
			});
			var marker1 = new BMap.Marker(data, {
				icon: myIcon,
				offset: new BMap.Size(0, -13)
			});
			marker1.markId = 'mark'
			this.map.addOverlay(marker1);
			// this.map.setViewport([data])
		},
		// 地图选点
		setPoint(val) {
			this.butName = val;
			this.map.addEventListener('click', (e) => {
				// console.log(e)
				this.selectPoint = '';
				var pointLng = e.point.lng;
				var pointLat = e.point.lat;
				this.setViewMap(e.point);
				var lnglats = this.BMapToAMap(pointLng, pointLat);; // Array.<LngLat>
				// // console.log(lnglats)
				var Alng = lnglats.lng.toString();
				var Alat = lnglats.lat.toString();
				var lng = Alng.slice(0, Alng.indexOf('.')) + Alng.slice(Alng.indexOf('.'), 10);
				var lat = Alat.slice(0, Alat.indexOf('.')) + Alat.slice(Alat.indexOf('.'), 10);
				this.selectPoint = lng + ',' + lat;
				// if (val == '企业') {
				// 	this.enterprise_info.location = lng + ',' + lat;
				// } else if (val == '项目') {
				// 	this.project_info.location = lng + ',' + lat;
				// } else if (val == '新增企业') {
				// 	this.addEnterprise.location = lng + ',' + lat;
				// } else if (val == '新增项目') {
				// 	this.addProject.location = lng + ',' + lat;
				// }


			});
		},
		// 地图选点后确认将坐标赋值
		Assignment() {
			var val = this.butName;
			if (val == '企业') {
				this.enterprise_info.location = this.selectPoint;
			} else if (val == '项目') {
				this.project_info.location = this.selectPoint;
			} else if (val == '新增企业') {
				this.addEnterprise.location = this.selectPoint;
			} else if (val == '新增项目') {
				this.addProject.location = this.selectPoint;
			}
			if (this.selectPoint) {
				this.addProject.address = '';
				let pointArr = this.selectPoint.split(',');
				// console.log(pointArr)
				var geoc = new BMap.Geocoder();
				geoc.getLocation(new BMap.Point(Number(pointArr[0]), Number(pointArr[1])), (rs) => {
					// // console.log(rs)
					this.addProject.address = '';
					if (rs) {
						var addComp = rs.addressComponents;
						this.addProject.address = addComp.province + "，" + addComp.city
					}

					// // console.log(addComp.province + ", " + addComp.city + ", " + addComp.district + ", " +
					// 	addComp.street + ", " + addComp.streetNumber);
				});
			}

			this.close('pick-point');
		},
		// 地图输入地址名称查询
		inquirePoint() {
			this.localSearch = new BMap.LocalSearch(this.map, {
				renderOptions: {
					map: this.map
				},
			});
			// 获取当前视图中心点
			var center = new BMap.Point(this.map.getCenter().lng, this.map.getCenter().lat);
			var geoc = new BMap.Geocoder();
			geoc.getLocation(center, (rs) => {
				var addComp = rs.addressComponents;
				this.localSearch.searchNearby(this.inputName, addComp.city);
				// 查询完成结果返回函数
				this.localSearch.setSearchCompleteCallback((point) => {
					// // console.log(point)
				});
				this.localSearch.setMarkersSetCallback((point) => {
					// console.log(point);
					var allOverlay = this.map.getOverlays();
					for (var i = 0; i < allOverlay.length - 1; i++) {
						// console.log(allOverlay[i])
						allOverlay[i].addEventListener('click', (e) => {
							// // console.log(e)
							this.setViewMap(e.point);
							e.domEvent.stopPropagation();
						});
					}
				});
			});
		},
		// 打开弹窗 
		open(val, status, data = {}) {
			$('.' + val).show();
			if (val != 'pick-point' && val != 'map-pop-ups' && val != 'enterprise-pop-ups' && val != "add-point" &&
				val != 'edit-point') {
				// if (this.judgment('authorityList', 56, this)) {
				this.getCompany();
				// }
			}

			if (val == 'add-equipment') {
				this.editEquipment = status;
			} else if (val == 'add-point') {
				// console.log(this.project_info)
				// 测点布设图
				this.$refs.pointImg.clearFile();
				this.$refs.imgSign.setUrl();
				// 项目描述
				this.describe.id = this.project_info.id;
				this.describe.content = this.project_info.content;
			} else if (val == 'edit-point') {
				this.editPoint = status;
				// 刷新测点布设图组件数据
				// // console.log(data)
				if(!this.editPoint){
					this.addPoint.name = 'ZD-';
				}
				if (data.url) {
					this.$refs.addPointImg.setFileData(data.url);
				}
			} else if (val == 'add-project') {

			} else if (val == 'pick-point') {
				this.inputName = '';
				this.selectPoint = '';
			} else if (val == 'describe') {
				// 项目描述（暂时停用）
				this.describe.id = this.project_info.id;
				this.describe.content = this.project_info.content;
			} else if (val == 'enterprise-pop-ups') {
				this.getUploadImg();
			} else if (val == "certificate") {
				this.certificate.serialNumber = data.ccno;
				if (data.tdate != '') {
					var time = data.tdate.split('/');
					this.certificate.years = time[0];
					this.certificate.month = time[1];
					this.certificate.day = time[2];
				}
				this.certificate.id = data.id;
			}
		},
		// 关闭弹窗
		close(val) {
			$('.' + val).hide();

			if (val == 'add-enterprise') {
				this.editMode = false;
				//恢复新增企业数据
				for (let key in this.addEnterprise) {
					if (key != 'id') {
						if (key != 'type') {
							this.addEnterprise[key] = '';
						} else {
							this.addEnterprise[key] = 1;
						}
					} else {
						this.addEnterprise[key] = null;
					}
				}
			} else if (val == 'add-project') {
				this.editMode = false;
				//恢复新增项目数据
				this.clearData(this.addProject);
			} else if (val == 'add-equipment') {
				//恢复新增设备数据
				for (let key in this.addEquipment) {
					this.addEquipment[key] = this.backupEquipment[key];
				}
			} else if (val == 'add-point') {
				// 测点布设图
				//恢复测点布设图测点数据
				this.clearData(this.addSettingMapPoint);
			} else if (val == 'edit-point') {
				this.$refs.addPointImg.clearFile();
				//恢复新增测点数据
				this.clearData(this.addPoint);
			} else if (val == 'enterprise-pop-ups') {
				for (let i = 1; i < 14; i++) {
					var uploadObj = this.$refs['file' + i];
					uploadObj.clearFile();
				}
			}
		},
		// 清空数据赋值信息
		clearData(data) {
			// // console.log(data)
			for (let key in data) {
				if (key != 'id') {
					data[key] = '';
				} else {
					data[key] = null;
				}
			}
		},
		// 页码变化监听
		handleCurrentChange(val) {
			this.pagenumber = val;
			this.currentPage = val;

			if (this.activeIndex == '1') {
				this.getDataList(this.equipmentCondition);
			} else if (this.activeIndex == '2') {
				this.getDataList(this.pointCondition);
			}
		},
		// 跳转页码输入
		number() {
			if (this.pagenumber > Math.ceil(this.total / this.pagesize)) {
				this.pagenumber = Math.ceil(this.total / this.pagesize);
			} else if (this.pagenumber < 1 && this.pagenumber.toString() != '') {
				this.pagenumber = 1;
			}
		},
		// 跳转
		jump() {
			if (this.pagenumber == '') {
				this.pagenumber = 1;
			}
			this.currentPage = Number(this.pagenumber);

			if (this.activeIndex == '1') {
				this.getDataList(this.equipmentCondition);
			} else if (this.activeIndex == '2') {
				this.getDataList(this.pointCondition);
			}
		},
		// 展开/折叠
		expandPanel(e) {
			var cla = $(e.currentTarget).attr('class');
			var child = $(e.currentTarget).parent().parent().next();
			if (cla.indexOf('expand') == -1) {
				child.show();
				$(e.currentTarget).addClass('expand');
				$(e.currentTarget).removeClass('animate-s');
				$(e.currentTarget).addClass('animate');
			} else {
				child.hide();
				$(e.currentTarget).removeClass('expand');
				$(e.currentTarget).addClass('animate-s');
			}
		}
	},
	components: {
		equipment,
		measuringPoint,
		uploadImg,
		imgSign,
		setupReport
	},
	beforeDestroy() {},
	watch: {}
}
