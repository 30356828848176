<template>
	<!-- 测点列表 -->
	<div class="list-box">
		<vue-scroll :ops="ops" class="sc">
			<ul class="list-title">
				<li class="li-width-xs center">序号</li>
				<li class="li-width-ls" v-show="parentObj.status == '企业'">项目</li>
				<li class="li-width-ls sort pointer" @click="dataSort($event, '测点')">
					测点编号
					<p>
						<!-- 未排序样式 -->
						<span class="icon iconfont icon-sort-up un-activation up"></span>
						<!-- 已排序样式 -->
						<span class="icon iconfont icon-sort-up-full activation up-full"></span>
						<!-- 未排序样式 -->
						<span class="icon iconfont icon-sort-down un-activation down"></span>
						<!-- 已排序样式 -->
						<span class="icon iconfont icon-sort-down-full activation down-full"></span>
					</p>
				</li>
				<li class="li-width-ls">仪器编号</li>
				<li class="li-width-l pointer">名称/位置</li>
				<li class="li-width-xs sort pointer justify-center" @click="dataSort($event, '文件')">
					文件数
					<p>
						<!-- 未排序样式 -->
						<span class="icon iconfont icon-sort-up un-activation up"></span>
						<!-- 已排序样式 -->
						<span class="icon iconfont icon-sort-up-full activation up-full"></span>
						<!-- 未排序样式 -->
						<span class="icon iconfont icon-sort-down un-activation down"></span>
						<!-- 已排序样式 -->
						<span class="icon iconfont icon-sort-down-full activation down-full"></span>
					</p>
				</li>
				<li class="li-width-xs sort center">
					<el-popover placement="end" width="160" v-model="visible">
						<div class="popover-box">
							<el-radio-group v-model="startAndStop" @change="statusFilter">
								<el-radio :label="2">全部</el-radio>
								<el-radio :label="0">已停用</el-radio>
								<el-radio :label="1">已启用</el-radio>
							</el-radio-group>
						</div>
						<div class="popover-but pointer" slot="reference">
							启停
							<span class="icon iconfont icon-filter"></span>
						</div>
					</el-popover>
				</li>
				<li class="li-width-ls pointer sort justify-center " @click="dataSort($event, '时间')">
					创建时间
					<p>
						<!-- 未排序样式 -->
						<span class="icon iconfont icon-sort-up un-activation up"></span>
						<!-- 已排序样式 -->
						<span class="icon iconfont icon-sort-up-full activation up-full"></span>
						<!-- 未排序样式 -->
						<span class="icon iconfont icon-sort-down un-activation down"></span>
						<!-- 已排序样式 -->
						<span class="icon iconfont icon-sort-down-full activation down-full"></span>
					</p>
				</li>
				<li class="li-width-xs center">操作</li>
			</ul>
			<div class="scroll-box list-scroll">
				<vue-scroll :ops="ops">
					<div class="ul-pop-ups">
						<ul class="list-content" v-for="(i, index) in list" :key="index">
							<li class="li-width-xs center">{{ index + 1 }}</li>
							<li v-show="parentObj.status == '企业'" class="li-width-ls" :title="i.projectName">
								<p class="li-p text-overflow">{{ i.projectName | dataDetect }}</p>
							</li>
							<li class="li-width-ls" :title="i.pointName">
								<p class="pointer pointNumber text-overflow" @click="pointClick(i.id, true)">{{ i.pointName | dataDetect }}</p>
							</li>
							<li class="li-width-ls" :title="i.deviceNum">{{ i.deviceNum | dataDetect }}</li>
							<li class="li-width-l" :title="i.address">{{ i.address | dataDetect }}</li>
							<li class="li-width-xs justify-center" :title="i.fileNum">{{ i.fileNum }}</li>
							<li class="li-width-xs setups-box  center">
								<el-button :param="i.id" v-if="i.state == '1'" type="primary" size="mini" @click="deactivate(i.id, i.pointName)" title="点击可停用">已启用</el-button>
								<el-button
									:param="i.id"
									v-if="i.state != '1'"
									type="warning"
									class="deactivate"
									size="mini"
									@click="open($event, 'p-pop-ups', '', i)"
									title="点击可启用"
								>
									已停用
								</el-button>
							</li>
							<li class="li-width-ls justify-center" :title="i.time">{{ i.time | dataDetect }}</li>
							<li class="li-width-xs center">
								<span v-show="judgment('authorityList', 283, parentObj)" class="icon pointer el-icon-edit" @click="open($event, 'edit-point', true, i)"></span>
								<span v-show="judgment('authorityList', 283, parentObj)" class="icon pointer el-icon-delete" @click="listDelete(i.id, i.pointName)"></span>
								<span v-show="!judgment('authorityList', 283, parentObj)" class="icon pointer el-icon-edit grey"></span>
								<span v-show="!judgment('authorityList', 283, parentObj)" class="icon pointer el-icon-delete grey"></span>
							</li>
						</ul>
					</div>
				</vue-scroll>
			</div>
		</vue-scroll>
		<!-- 弹窗 -->
		<div class="p-pop-ups" @click="close('', 'p-pop-ups')">
			<div class="main-pop point-pop-ups" @click="(e)=>{e.stopPropagation();}">
				<p class="pop-ups-title">
					启用
					<!-- 关闭按钮 -->
					<span class="pointer close el-icon-close" @click="close($event, 'p-pop-ups')"></span>
				</p>
				<div class="contentbox">
					<div class="scroll-box">
						<vue-scroll>
							<div class="pop-ups-content">
								<el-radio-group v-model="radio">
									<el-radio v-for="(item, inde) in measuringPoint" :label="item.deviceNum" :key="inde">{{ item.deviceNum }}</el-radio>
								</el-radio-group>
							</div>
						</vue-scroll>
					</div>
					<div class="bottom-box"><el-button :disabled="radio == ''" type="primary" size="mini" @click="bindMeasuringPoint($event)">绑定</el-button></div>
				</div>
			</div>
		</div>
		<!-- 弹窗 -->
		<el-dialog title="测点日志" :append-to-body="true" custom-class="point-info-box" :visible.sync="dialogTableVisible">
			<div class="point-info">
				<ul class="list-title">
					<li style="width: 10%;">序号</li>
					<li style="width: 20%;">设备编号</li>
					<li style="width: 45%;">内容</li>
					<li style="width: 20%;">时间</li>
				</ul>
				<div class="info-scroll-box">
					<vue-scroll v-show="item.total">
						<ul class="info-ul" v-for="(i, index) in item.row" :key="index">
							<li style="width: 10%;">{{ index + 1 }}</li>
							<li style="width: 20%;">{{ i.dno }}</li>
							<li style="width: 45%;">{{ i.content }}</li>
							<li style="width: 20%;">{{ i.time }}</li>
						</ul>
					</vue-scroll>
					<noData v-show="!item.total" />
				</div>
				<div class="bottom-pagination"><Pagination :item="item" :current-page-change="currentPageChange"></Pagination></div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: '',
	props: {
		list: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			measuringPoint: [], //设备列表数据
			defaults: '', // 弹窗中输入框参数
			radio: '', //输入框单选框选中的选项
			radios: '', //单选框组选中的参数
			pointId: null, // 启用测点id

			editPoint: {
				name: '', //测点名称
				model: '', // 型号/参数
				lng: '', //经度
				lat: '' //纬度
			}, // 测点编辑

			parentObj: null, //父级this
			visible: false, //Popover 弹出框变量

			sortData: null, // 排序数据
			stateData: null, // 状态筛选数据

			startAndStop: 2, //启停显示筛选条件

			ops: {
				bar: {
					onlyShowBarOnScroll: false, //不止只在滚动时显示 bar。
					keepShow: true //滚动条一直显示
				}
			}, //滚动条设置
			dialogTableVisible: false, //测点信息弹窗是否显示
			item: {
				rows: [],
				page: 1,
				size: 50,
				allPage: 0,
				total: 0,
				time: false,
				type: 'measuringPoint',
				dataType: 'list',
				text: '暂时没有数据哦--'
			}, //测点信息列表
			clickPointID: null // 点击测点编号的测点id
		};
	},
	created() {
		this.parentObj = this.$parent;
	},
	mounted() {},
	computed: {},
	methods: {
		// 设置设备数据
		setChild(data) {
			this.measuringPoint = data;
		},
		// gubd 获取启用测点列表
		getEnableList() {
			var data = {
				companyId: this.$parent.enterprise_info.id,
				projectId: this.$parent.project_info.id
			};
			this.axios.post('web2/mang/dmj/gubd', data).then(res => {
				this.measuringPoint = [];
				if (res.status) {
					this.measuringPoint = res.data;
				}
			});
		},
		// 状态筛选
		statusFilter() {
			$('.un-activation').show();
			$('.activation').hide();
			var data = {
				tp: 4,
			};
			this.$parent.currentPage = 1;
			if (this.startAndStop == 0) {
				data.st = 3;
			} else if (this.startAndStop == 1) {
				data.st = 2;
			} else if (this.startAndStop == 2) {
				data.st = 1;
			}
			this.stateData = data;
			this.sortData = null;
			this.$parent.getDataList(data);
		},
		// 数据排序点击事件
		dataSort(e, val) {
			var obj = $(e.currentTarget);
			obj.siblings()
				.find('.un-activation')
				.show();
			obj.siblings()
				.find('.activation')
				.hide();
			var upfull = obj.find('.up-full').is(':hidden');
			var data = {};
			this.$parent.currentPage = 1;
			if (upfull) {
				// 升序
				obj.find('.up').hide();
				obj.find('.up-full').show();
				obj.find('.down').show();
				obj.find('.down-full').hide();
				if (val == '测点') {
					data.tp = 2;
					data.st = 1;
				} else if (val == '文件') {
					data.tp = 6;
					data.st = 1;
				} else if (val == '时间') {
					data.tp = 5;
					data.st = 1;
				}
			} else {
				// 降序
				obj.find('.up').show();
				obj.find('.up-full').hide();
				obj.find('.down').hide();
				obj.find('.down-full').show();
				if (val == '测点') {
					data.tp = 2;
					data.st = 2;
				} else if (val == '文件') {
					data.tp = 6;
					data.st = 2;
				} else if (val == '时间') {
					data.tp = 5;
					data.st = 2;
				}
			}
			this.stateData = null;
			this.sortData = data;
			this.$parent.getDataList(data);
		},
		// 测点编号点击事件
		pointClick(id, reset = false) {
			if(reset){
				this.item.page = 1;
				this.item.size = 50;
			}
			
			this.clickPointID = id;
			var data = {
				id,
				page: this.item.page,
				size: this.item.size
			};
			this.axios.post('web2/mang/odnr/gplg', data).then(res => {
				this.item.row = [];
				this.item.total = 0;
				this.item.allPage = 0;
				if (res.status) {
					this.item.row = res.data.list;
					this.item.total = res.data.total;
					this.item.allPage = Math.ceil(res.data.total / this.item.size);
				}
				this.dialogTableVisible = true;
			});
		},
		// 绑定设备
		bindMeasuringPoint(e) {
			// // console.log(this.defaults, '车');
			// // console.log(this.radio);
			var data = {
				id: this.pointId,
				deviceNum: this.radio
			};
			this.axios.post('web2/mang/dmj/bpd', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '启用成功!'
					});
					this.close('', 'p-pop-ups');
					if (this.sortData != null) {
						this.$parent.getDataList(this.sortData);
					} else if (this.stateData != null) {
						this.$parent.getDataList(this.stateData);
					} else {
						this.$parent.getDataList();
					}
				}
			});
		},
		// 停用设备
		deactivate(id, pointName) {
			this.$confirm('此操作将停用<strong>'+ pointName +'</strong>，是否继续?', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('web2/mang/dmj/relivep', { id }).then(res => {
						if (res.status) {
							this.$message({
								type: 'success',
								message: '停用成功!'
							});
							if (this.sortData != null) {
								this.$parent.getDataList(this.sortData);
							} else if (this.stateData != null) {
								this.$parent.getDataList(this.stateData);
							} else {
								this.$parent.getDataList();
							}
						}
					});
				})
				.catch(() => {
					// this.$message({
					// 	type: 'info',
					// 	message: '已取消停用'
					// });
				});
		},
		// 列表删除
		listDelete(id, pointName) {
			this.$confirm('此操作将删除<strong>'+ pointName +'</strong>下所有数据，是否继续！', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				customClass: 'icon-big',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				type: 'warning',
				center: true,
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios
						.post('web2/mang/dmj/dlp', {
							id
						})
						.then(res => {
							if (res.status) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '删除成功!'
								});
								if (this.sortData != null) {
									this.$parent.getDataList(this.sortData);
								} else if (this.stateData != null) {
									this.$parent.getDataList(this.stateData);
								} else {
									this.$parent.getDataList();
								}
							}
						});
				})
				.catch(() => {});
		},
		// 打开弹窗(启用)
		open(e, obj, status, data = {}) {
			if (obj == 'p-pop-ups') {
				var w = $('.left-box').width();
				var objW = $('.point-pop-ups').width();
				var left = e.pageX - objW - parseInt(objW / 3);
				$('.point-pop-ups').css({
					left: left + 'px'
				});
				$('.p-pop-ups').show();
				this.pointId = data.id;
				this.getEnableList();
			} else {
				if (obj == 'edit-point') {
					// console.log(data);
					this.$parent.editPoint = status;
					// this.$parent.addPoint.name = data.pointName.split('-')[1];
					this.$parent.addPoint.name = data.pointName;
					this.$parent.addPoint.position = data.address;
					this.$parent.addPoint.pn1 = data.pn1;
					// this.$parent.addPoint.lng = data.;
					// this.$parent.addPoint.lat = data.;
					this.$parent.addPoint.id = data.id;
					var val = {
						url: ''
					};
					if (data.pn1) {
						val.url = this.constData.imgUrl + 'res/static/ptpc/1' + data.pn1;
					}
					this.$parent.open(obj, status, val);
				} else {
					this.$parent.open(obj, status);
				}
			}
		},
		// 关闭弹窗
		close(e, obj) {
			if (obj == 'p-pop-ups') {
				this.defaults = '';
				this.radio = '';
				this.radios = '';
			}
			$('.' + obj).hide();
		},
		// 弹窗中菜单选择
		handleClick(tab, event) {},
		//翻页组件触发分页回调
		currentPageChange(type) {
			if (type === 'measuringPoint') {
				this.pointClick(this.clickPointID);
			}
		},
		// 还原数据
		restoreData(){
			this.item.page = 1;
			this.item.size = 50;
		},
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
// 数据列表
.list-box {
	width: 100%;
	height: calc(100% - 100px);

	ul {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.justify-center {
			justify-content: center !important;
		}

		li {
			padding: 5px 0;
			display: flex;
			// justify-content: center;
			align-items: center;
			position: relative;

			::v-deep {
				.el-popover {
					top: 27px;
					left: 40px;
					width: 60px !important;
					min-width: 60px !important;

					.popover-box {
						height: 60px;
					}
				}
				.el-radio-group {
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
				}
			}

			.popover-but {
				width: 55px;
				// height: 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		.center {
			justify-content: center;
		}

		.pointNumber:hover {
			color: #409eff;
		}

		p {
			height: 30px;
			display: flex;
			justify-content: space-around;
			flex-direction: column;
			margin-top: 4px;
			cursor: pointer;

			.up,
			.up-full {
				margin-bottom: -12.5px;
			}

			.down,
			.down-full {
				margin-top: -12.5px;
			}

			.activation {
				display: none;
			}
		}
	}

	.list-title {
		width: calc(100% - 20px);
		margin: 0 10px;
		height: 45px;
		// background-color: #f2f6fc;
		// background-color: #87cbcb;
		background-color: #ebeef5;

		li {
			font-size: 15px;
			font-weight: 600;
			color: #4c4e51;
			letter-spacing: 1px;
		}

		.sort {
			// color: #409eff;
			color: #008bcb;
		}
	}

	.pop-ups-title {
		// 关闭
		.close {
			font-size: 16px;
			color: #fff;
		}
	}

	.sc {
		// vue-scroll插件内部滚动区域样式更改。避免多个vue-scroll套用时高度不能按照外部盒子高度进行滚动
		::v-deep {
			.__view:first-child {
				height: 100%;
			}
		}
	}

	.scroll-box {
		height: calc(100% - 45px);

		// 滚动内容包裹盒子
		.ul-pop-ups {
			width: calc(100% - 20px);
			padding: 0 10px;
			position: relative;

			.list-content {
				padding: 5px 0;

				li {
					font-size: 16px;
					color: #000000;
					
					p{
						margin-top: 0;
					}

					.icon {
						font-size: 20px;
					}

					.grey {
						color: #909399 !important;
					}

					.el-icon-edit {
						color: #409eff;
					}

					.el-icon-delete {
						color: #e56c6c;
					}

					.el-icon-delete {
						margin-left: 15px;
					}
				}

				.setups-box {
					position: relative;

					::v-deep {
						.el-button {
							width: 56px !important;
							height: 28px !important;
							line-height: 28px;
							text-align: center;
							padding: 0 !important;
						}
					}

					.deactivate {
						background-color: #ff905f;
					}
				}
			}

			.list-content:nth-child(even) {
				background-color: #f2f6fc;
			}
		}
	}

	.li-width-ls {
		width: 160px;
	}

	.li-width-l {
		width: 170px;
	}

	.li-width-s {
		width: 80px;
	}

	.li-width-xs {
		width: 68px;
	}
	// 启用弹窗
	.p-pop-ups {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		display: none;
		z-index: 1000;
		
		.main-pop {
			width: 300px;
			height: 280px;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			margin: auto 0;

			// 菜单样式更改
			::v-deep {
				.el-tabs__item {
					padding: 0;
					margin-left: 15px;
				}
				// 菜单选中样式更改
				.is-active {
					border-bottom: 2px solid #409eff;
				}
				// 去除菜单自带选中时的文字下划线
				.el-tabs__active-bar {
					display: none;
				}
			}

			.pop-ups-title {
				// 箭头指示
				.instructions {
					position: absolute;
					top: -18px;
					right: -19px;
					font-size: 18px !important;
					color: #00a7fa;
					transform: rotate(-25deg);
				}
			}

			.contentbox {
				// padding-top: 8px;
				border: thin solid #00a7fa;
				background-color: #ffffff;

				.scroll-box {
					height: 200px;

					.pop-ups-content {
						display: flex;
						flex-direction: column;

						::v-deep {
							// 输入框样式更改
							.el-input__inner {
								width: auto;
								height: 25px;
								// border: none;
							}

							// 单选框组样式更改
							.el-radio-group {
								display: flex;
								flex-direction: column;
							}
						}

						// 单选框label样式更改
						.el-radio {
							height: 25px;
							line-height: 25px;
							margin: 0px 10px 8px 10px;
						}

						ul {
							width: 100%;
							display: flex;
							flex-direction: column;
							justify-content: flex-start;

							li {
								margin-bottom: 10px;
								padding: 0 10px 0 15px;
								justify-content: space-between;
								font-size: 13.5px;
							}
						}
					}
				}

				.bottom-box {
					margin: 10px 0;
					text-align: center;
				}
			}
		}
	}
}
</style>

<style lang="scss">
.point-info-box {
	width: 800px;

	.el-dialog__header {
		padding: 10px 20px 10px;
		font-size: 14px;
		text-align: center;
		background-color: #66b1ff;

		.el-dialog__title {
			color: #fff;
		}

		.el-dialog__headerbtn {
			top: 7px;

			i {
				color: #fff;
			}
		}
	}

	.point-info {
		ul {
			display: flex;
			justify-content: space-between;

			li {
				padding: 5px 0;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				margin-left: 6px;

				.el-popover {
					top: 27px;
					left: 40px;
					width: 60px !important;
					min-width: 60px !important;

					.popover-box {
						height: 60px;
					}
				}

				.el-radio-group {
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
				}

				.popover-but {
					width: 44px;
					// height: 20px;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}

			li:first-child {
				margin-left: 0;
			}
		}

		.list-title {
			height: 35px;
			background-color: #f2f6fc;

			.sort {
				color: #409eff;
			}
		}

		.info-scroll-box {
			height: 380px;
			margin-bottom: 20px;
		}

		.bottom-pagination {
			width: 100%;

			.tableFooter {
				background: none;
				display: flex;
				justify-content: flex-end;

				.pageNumber {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
	}
}
</style>
