<template>
	<!-- 振动设备管理 -->
	<div class="equipmentManagement">
		<div class="equipmentManagement-main">
			<!-- 选择框盒子 -->
			<div class="left-box">
				<div class="top">
					<div class="search-button">
						<p class="title-text margin-bottom">企业项目</p>
						<el-input placeholder="请输入公司名称" autocomplete="off" v-model="searchContent">
							<el-button slot="append" size="small" icon="el-icon-search" @click="search"></el-button>
						</el-input>
						<div class="but-box">
							<el-button type="primary" size="mini" title="添加企业" @click="open('add-enterprise')" v-show="judgment('authorityList', 274, this)">
								<span class="icon iconfont icon-tianjiaqiyekehu"></span>
							</el-button>
							<el-button type="primary" size="mini" title="添加项目" @click="open('add-project')" v-show="judgment('authorityList', 275, this)">
								<span class="icon iconfont icon-tianjiaxiangmu"></span>
							</el-button>
						</div>
					</div>
				</div>
				<div class="scroll-list-box">
					<vue-scroll>
						<div class="scroll-box">
							<ul class="parent" v-for="(i, index) in listData" :key="index">
								<li class="title-li pointer">
									<p class="icon-box"><span v-show="i.children.length != 0" @click="expandPanel" class="el-icon-caret-right caret icon"></span></p>
									<span class="text text-overflow text-box" :status="i.hasOwnProperty('pn') ? '企业' : '项目'" @click.stop="selectText($event, i)">
										<i class="number">{{ index + 1 }}</i>
										<span class="parent-name" :title="i.name">{{ i.name }}</span>
									</span>
								</li>
								<li class="child">
									<ul>
										<li
											:class="item.operating === 0 ? 'text pointer text-overflow content-li end' : 'text pointer text-overflow content-li'"
											status="项目"
											v-for="(item, ind) in i.children"
											:key="ind"
											@click.stop="selectText($event, item, i.id, i)"
										>
											<i class="number">{{ ind + 1 }}</i>
											<span class="child-name" :title="item.name">{{ item.name }}</span>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</vue-scroll>
				</div>
			</div>
			<!-- 列表盒子 -->
			<div class="right-box" v-if="status != ''">
				<!-- 头部信息显示 -->
				<div class="info-box">
					<div class="top">
						<p class="title" v-if="judgment('authorityList', 274, this)">{{ (status == '企业' && judgment('authorityList', 274, this) ? enterprise_info.name + "（" + enterprise_info.typeName + "）": project_info.name) | dataDetect }}</p>
						<p class="title" v-else>{{ (status == '企业' ? enterprise_info.name : project_info.name) | dataDetect }}</p>
					</div>
					<div class="enterprise" v-show="status == '企业'">
						<!-- <vue-scroll :ops="ops"> -->
						<div class="info-content">
							<div class="content-box">
								<div class="cell width-s">
									<span class="name">负责人:</span>
									<span class="content text-overflow" :title="enterprise_info.person | dataDetect">{{ enterprise_info.person | dataDetect }}</span>
								</div>
								<div class="cell width-l">
									<span class="name">地址:</span>
									<span class="icon iconfont icon-ziyuan pointer address-i" @click="setMap(enterprise_info.location, 'view-map', '企业')"></span>
									<span class="content-s text-overflow" :title="enterprise_info.address | dataDetect">{{ enterprise_info.address | dataDetect }}</span>
								</div>
							</div>
							<!-- <div class="imgs-box" v-show="judgment('authorityList', 306, this)">
								<div class="img-cell">
									<span class="name">电子章：</span>
									<el-image v-if="enterprise_info.img1 != ''" style="width: 100px; height: 60px" :src="enterprise_info.pn1" fit="scale-down"></el-image>
									<el-image v-show="enterprise_info.img1 == ''" style="width: 100px; height: 60px">
										<div slot="error" class="image-slot"><p>暂无图片</p></div>
									</el-image>
								</div>
								<div class="img-cell">
									<span class="name">检测签名：</span>
									<el-image v-if="enterprise_info.img2 != ''" style="width: 100px; height: 60px" :src="enterprise_info.pn2" fit="scale-down"></el-image>
									<el-image v-show="enterprise_info.img2 == ''" style="width: 100px; height: 60px">
										<div slot="error" class="image-slot"><p>暂无图片</p></div>
									</el-image>
								</div>
								<div class="img-cell">
									<span class="name">审核签名：</span>
									<el-image v-if="enterprise_info.img3 != ''" style="width: 100px; height: 60px" :src="enterprise_info.pn3" fit="scale-down"></el-image>
									<el-image v-show="enterprise_info.img3 == ''" style="width: 100px; height: 60px">
										<div slot="error" class="image-slot"><p>暂无图片</p></div>
									</el-image>
								</div>
							</div> -->
							<div class="button-box">
								<!-- <el-button v-show="judgment('authorityList', 276, this)" type="primary" size="mini" @click="getSetup(1)">报告设置</el-button> -->
								<el-button v-show="judgment('authorityList', 306, this)" plain type="primary" size="mini" @click="open('enterprise-pop-ups')">报告图</el-button>
								<el-button v-show="judgment('authorityList', 276, this)" plain type="primary" icon="el-icon-edit" size="mini" @click="infoEdit('企业')"></el-button>
								<el-button
									v-show="judgment('authorityList', 277, this)"
									type="danger"
									plain
									icon="el-icon-delete"
									size="mini"
									@click="enterpriseDelete(enterprise_info.id, enterprise_info.name)"
								></el-button>
								<!-- 	<el-button v-show="editMode" size="small" type="primary" @click="modifyEnterprise">确定</el-button>
									<el-button v-show="editMode" size="small" type="info" @click="cancelModify('企业')">取消</el-button> -->
							</div>
						</div>
						<!-- </vue-scroll> -->
					</div>
					<div class="project" v-show="status == '项目'">
						<vue-scroll :ops="ops">
							<div class="info-content">
								<div class="content-box">
									<div class="cell width-s">
										<span class="name">负责人:</span>
										<span class="content text-overflow" :title="project_info.person | dataDetect">{{ project_info.person | dataDetect }}</span>
									</div>
									<div class="cell width-l">
										<span class="name">地址:</span>
										<span class="icon iconfont icon-ziyuan pointer address-i" @click="setMap(project_info.location, 'view-map', '项目')"></span>
										<span class="content-s text-overflow" :title="project_info.address | dataDetect">{{ project_info.address | dataDetect }}</span>
									</div>
									<div class="cell width-l">
										<span class="name">爆破单位:</span>
										<span class="content-ss text-overflow" :title="project_info.bcname | dataDetect">{{ project_info.bcname | dataDetect }}</span>
									</div>
									<!-- <div class="cell width-xs scenario" v-show="editMode">
										<span class="name">场景</span>
										<el-select popper-class="short-select" v-model="project_info.en" placeholder="请选择场景">
										    <el-option
										      v-for="item in scenario"
										      :key="item.value"
										      :label="item.label"
										      :value="item.label"
											  >
										    </el-option>
										  </el-select>
									</div> -->
								</div>
								<div class="button-box">
									<el-button v-show="judgment('authorityList', 309, this)" plain type="primary" size="mini" @click="getSetup(2)">报告设置</el-button>
									<el-button v-show="judgment('authorityList', 308, this)" plain type="primary" size="mini" @click="open('add-point')">项目设置</el-button>
									<!-- <el-button v-show="judgment('authorityList', 310, this)" plain type="primary" size="mini" @click="open('describe')">项目描述</el-button> -->
									<el-button v-show="judgment('authorityList', 278, this)" plain type="primary" size="mini" @click="endProject">结束项目</el-button>
									<el-button
										v-show="judgment('authorityList', 278, this)"
										plain
										type="primary"
										icon="el-icon-edit"
										size="mini"
										@click="infoEdit('项目')"
									></el-button>
									<el-button
										v-show="judgment('authorityList', 279, this)"
										type="danger"
										plain
										icon="el-icon-delete"
										size="mini"
										@click="projectDelete(project_info.id, project_info.name)"
									></el-button>
									<!-- <el-button v-show="editMode" size="small" type="primary" @click="modifyProject">确定</el-button>
									<el-button v-show="editMode" size="small" type="info" @click="cancelModify('项目')">取消</el-button> -->
								</div>
							</div>
						</vue-scroll>
					</div>
				</div>
				<div class="menu-list-box">
					<!-- 列表菜单 -->
					<div class="menu-box">
						<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
							<el-menu-item index="1">设备</el-menu-item>
							<el-menu-item index="2">测点</el-menu-item>
						</el-menu>
						<div class="search-button">
							<el-input placeholder="请输入内容" autocomplete="off" v-model="searchCondition">
								<el-button slot="append" size="small" icon="el-icon-search" @click="fuzzyQuery"></el-button>
							</el-input>
							<el-button
								class="but"
								type="primary"
								plain
								size="small"
								v-show="activeIndex == '1' && judgment('authorityList', 280, this)"
								@click="open('add-equipment', false)"
							>
								添加设备
							</el-button>
							<el-button
								class="but"
								type="primary"
								plain
								size="small"
								:disabled="!judgment('authorityList', 283, this)"
								v-show="activeIndex == '2' && status == '项目'"
								@click="open('edit-point', false)"
							>
								添加测点
							</el-button>
						</div>
					</div>
					<!-- 设备列表 -->
					<equipment v-show="activeIndex == '1'" :list="equipmentList" ref="equipment"></equipment>

					<!-- 测点列表 -->
					<measuringPoint v-show="activeIndex == '2'" :list="pointList" ref="measuringPoint"></measuringPoint>

					<!-- 底部分页 -->
					<div class="bottombox">
						<el-pagination
							@current-change="handleCurrentChange"
							:current-page.sync="currentPage"
							:page-size="pagesize"
							:pager-count="5"
							background
							layout="prev, pager, next, total, slot"
							:total="total"
						>
							<div style="display: inline-block;">
								<div class="slotbox">
									<span>到第</span>
									<input type="number" @input="number()" v-model="pagenumber" class="pageinput" />
									<span>页</span>
									<button @click="jump">确认</button>
								</div>
							</div>
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<!-- 新增企业 -->
		<div class="add-enterprise Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>{{ editMode ? '修改企业' : '新增企业' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('add-enterprise')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="cell">
						<span class="title">
							<i>*</i>
							企业名称
						</span>
						<p class="cell-content" v-show="!judgment('authorityList', 274, this)">{{addEnterprise.name}}</p>
						<el-input v-if="judgment('authorityList', 274, this)" v-model="addEnterprise.name" placeholder="请输入内容"></el-input>
					</div>
					<div class="cell" v-if="judgment('authorityList', 274, this)">
						<span class="title">类型</span>
						<el-select v-model="addEnterprise.type" filterable placeholder="请选择">
						    <el-option
						      v-for="item in enterprisType"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
					</div>
					<div class="cell">
						<span class="title">坐标</span>
						<el-input class="coordinate" v-model="addEnterprise.location" placeholder="请输入内容">
							<el-button slot="append" size="mini" @click="setMap(addEnterprise.location, 'pick-point', '新增企业')"><span class="icon iconfont icon-ziyuan"></span></el-button>
						</el-input>
					</div>
					<div class="cell">
						<span class="title">地址</span>
						<el-input v-model="addEnterprise.address" placeholder="请输入内容"></el-input>
					</div>
					<div class="button-box">
						<el-button v-show="!editMode" :disabled="addEnterprise.name == ''" type="primary" size="mini" @click="addCompany">添加</el-button>
						<el-button v-show="editMode" size="small" type="primary" @click="modifyEnterprise">修改</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 新增\修改项目 -->
		<div class="add-project Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>{{ editMode ? '修改项目' : '新增项目' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('add-project')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="cell">
						<span class="title">
							<i>*</i>
							项目名称
						</span>
						<el-input v-model="addProject.name" placeholder="请输入内容"></el-input>
					</div>
					<!-- v-show="judgment('authorityList', 207, this)" -->
					<div class="cell">
						<span class="title">
							<i>*</i>
							所属企业
						</span>
						<el-select :disabled="editMode" v-model="addProject.companyId" filterable placeholder="请选择企业">
							<el-option v-for="item in allEnterprise" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="cell">
						<span class="title">爆破单位</span>
						<el-input v-model="addProject.bcname" placeholder="请填写爆破单位"></el-input>
					</div>
					<div class="cell">
						<span class="title">坐标</span>
						<el-input class="coordinate" v-model="addProject.location" placeholder="请输入内容">
							<el-button slot="append" size="mini" @click="setMap(addProject.location, 'pick-point', '新增项目')"><span class="icon iconfont icon-ziyuan"></span></el-button>
						</el-input>
					</div>
					<div class="cell">
						<span class="title">地址</span>
						<p class="text">{{addProject.address}}</p>
						<!-- <el-input readonly v-model="addProject.address" placeholder="请输入内容"></el-input> -->
					</div>
					<div class="cell" v-show="!editMode">
						<span class="title">描述</span>
						<el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="addProject.content"></el-input>
					</div>
					<div class="button-box">
						<el-button v-show="!editMode" :disabled="addProject.name == '' || addProject.companyId == ''" type="primary" size="mini" @click="addProjectf">添加</el-button>
						<el-button v-show="editMode" size="small" type="primary" @click="modifyProject">修改</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 修改 -->
		<!-- 编辑项目描述 -->
		<div class="describe Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>项目描述</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('describe')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="cell">
						<!-- <span class="title">描述</span> -->
						<el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="describe.content"></el-input>
					</div>
					<div class="button-box"><el-button :disabled="describe.content == ''" type="primary" size="mini" @click="editDescription">修改</el-button></div>
				</div>
			</div>
		</div>
		<!-- 报告图片上传弹窗 -->
		<div class="enterprise-pop-ups Mask-box">
			<div class="pop-ups-box box-center">
				<p class="pop-ups-title">
					<span>图片上传</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('enterprise-pop-ups')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="upload-file-box">
						<vue-scroll>
							<el-divider content-position="left">盖章</el-divider>
							<div class="cube-box file-cube-box border-b">
								<div class="cube file-cube">
									<p class="cude-title">
										CMA章
										<br />
										<i>*推荐尺寸：94 x 69（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file1" :configuration="parameter1"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter1.name)" v-show="reportFigure[parameter1.name] != ''"></i> -->
								</div>
								<div class="cube file-cube border-l">
									<p class="cude-title">
										正本章
										<br />
										<i>*推荐尺寸：77 x 40（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file2" :configuration="parameter2"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter2.name)" v-show="reportFigure[parameter2.name] != ''"></i> -->
								</div>
								<div class="cube file-cube border-l border-r">
									<p class="cude-title">
										检测专用章
										<br />
										<i>*推荐尺寸：111 x 111（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file3" :configuration="parameter3"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter3.name)" v-show="reportFigure[parameter3.name] != ''"></i> -->
								</div>
							</div>
							<el-divider content-position="left">签名</el-divider>
							<div class="cube-box file-cube-box border-b">
								<div class="cube file-cube border-l">
									<p class="cude-title">
										检测签名
										<br />
										<i>*推荐尺寸：71 x 32（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file4" :configuration="parameter4"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter4.name)" v-show="reportFigure[parameter4.name] != ''"></i> -->
								</div>
								<div class="cube file-cube border-l">
									<p class="cude-title">
										审核签名
										<br />
										<i>*推荐尺寸：71 x 32（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file5" :configuration="parameter5"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter5.name)" v-show="reportFigure[parameter5.name] != ''"></i> -->
								</div>
								<div class="cube file-cube border-l border-r">
									<p class="cude-title">
										批准签名
										<br />
										<i>*推荐尺寸：71 x 32（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file6" :configuration="parameter6"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter6.name)" v-show="reportFigure[parameter6.name] != ''"></i> -->
								</div>
							</div>
							<el-divider content-position="left">相关资质</el-divider>
							<div class="cube-box file-cube-box">
								<div class="cube file-cube border-l">
									<p class="cude-title">
										企业营业执照
										<br />
										<i>*推荐尺寸：413 x 592（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file7" :configuration="parameter7"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter7.name)" v-show="reportFigure[parameter7.name] != ''"></i> -->
								</div>
								<div class="cube file-cube border-l">
									<p class="cude-title">
										计量认证证书
										<br />
										<i>*推荐尺寸：413 x 592（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file8" :configuration="parameter8"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter8.name)" v-show="reportFigure[parameter8.name] != ''"></i> -->
								</div>
								<div class="cube file-cube border-l">
									<p class="cude-title">
										计量认证证书参数
										<br />
										<i>*推荐尺寸：413 x 644（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file9" :configuration="parameter9"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter9.name)" v-show="reportFigure[parameter9.name] != ''"></i> -->
								</div>
								<div class="cube file-cube border-l">
									<p class="cude-title">
										质量和技术负责人资格证书1
										<br />
										<i>*推荐尺寸：413 x 296（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file10" :configuration="parameter10"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter10.name)" v-show="reportFigure[parameter10.name] != ''"></i> -->
								</div>
							</div>
							<div class="cube-box file-cube-box">
								<div class="cube file-cube border-l">
									<p class="cude-title">
										质量和技术负责人资格证书2
										<br />
										<i>*推荐尺寸：413 x 296（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file11" :configuration="parameter11"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter11.name)" v-show="reportFigure[parameter11.name] != ''"></i> -->
								</div>
								<div class="cube file-cube border-l">
									<p class="cude-title">
										监测员资格证书1
										<br />
										<i>*推荐尺寸：413 x 281（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file12" :configuration="parameter12"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter12.name)" v-show="reportFigure[parameter12.name] != ''"></i> -->
								</div>
								<div class="cube file-cube border-l border-r">
									<p class="cude-title">
										监测员资格证书2
										<br />
										<i>*推荐尺寸：413 x 281（单位：像素）</i>
									</p>
									<div class="file-box"><upload-img ref="file13" :configuration="parameter13"></upload-img></div>
									<!-- <i class="delete-icon el-icon-delete" @click="imgDelete(parameter13.name)" v-show="reportFigure[parameter13.name] != ''"></i> -->
								</div>
							</div>
						</vue-scroll>
					</div>
					<div class="bottom-but"><el-button type="primary" @click="getimglist()">确定</el-button></div>
				</div>
			</div>
		</div>
		<!-- 添加/编辑设备 -->
		<div class="add-equipment equipment-style Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>{{ editEquipment ? '编辑' : '添加设备' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('add-equipment')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="cell">
						<span class="title">
							<i>*</i>
							仪器编号
						</span>
						<el-input v-show="!editEquipment" v-model="addEquipment.numbering" placeholder="请输入内容"></el-input>
						<p class="cell-content" v-show="editEquipment">{{ addEquipment.numbering }}</p>
					</div>
					<!--  -->
					<div class="cell" v-show="judgment('authorityList', 276, this)">
						<span class="title">所属企业</span>
						<el-select v-model="addEquipment.companyId" filterable placeholder="请选择企业">
							<el-option v-for="item in allEnterprise" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="cell">
						<span class="title">型号/参数</span>
						<el-input v-model="addEquipment.type" placeholder="请输入内容"></el-input>
					</div>
					<div class="cell">
						<span class="title">单位</span>
						<el-input v-model="addEquipment.unit" placeholder="请输入内容"></el-input>
					</div>
					<div class="cell">
						<span class="title">电量报警开关</span>
						<el-switch v-model="addEquipment.pas" active-text="开" inactive-text="关" :active-value="1" :inactive-value="0"></el-switch>
					</div>
					<div class="button-box">
						<el-button type="primary" :disabled="addEquipment.numbering == ''" v-show="!editEquipment" size="mini" @click="addEquipments">添加</el-button>
						<el-button type="primary" v-show="editEquipment" size="mini" @click="equipmentEdit">修改</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 查看/编辑校准证书信息 -->
		<div class="certificate Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>校准证书信息</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('certificate')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="cell">
						<span class="title"><i>*</i>校准证书编号</span>
						<el-input type="number" class="number" placeholder="请输入" @blur="automaticallyFill" v-model="certificate.serialNumber">
							<template slot="prepend">
								校准字第
							</template>
							<template slot="append">
								号
							</template>
						</el-input>
					</div>
					<div class="cell">
						<span class="title"><i>*</i>有效期</span>
						<el-input type="number" class="tvalidity" placeholder="" v-model="certificate.years">
							<template slot="append">
								年
							</template>
						</el-input>
						<el-input type="number" class="tvalidity" placeholder="" v-model="certificate.month">
							<template slot="append">
								月
							</template>
						</el-input>
						<el-input type="number" class="tvalidity" placeholder="" v-model="certificate.day">
							<template slot="append">
								日
							</template>
						</el-input>
					</div>
					<div class="button-box">
						<el-button
							:disabled="certificate.serialNumber == '' || certificate.periodOfValidity == ''"
							type="primary"
							size="mini"
							@click="editorCertificateInformation"
						>
							修改
						</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 添加/编辑测点布设图和项目描述 -->
		<div class="add-point point-style Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>{{ settingMap ? '项目设置' : '项目设置' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('add-point')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="top-box">
						<p class="title">项目描述</p>
						<el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="describe.content"></el-input>
					</div>
					<div class="bottom-box">
						<div class="top-but">
							<span class="title">测点布设图</span>
							<el-button v-show="this.project_info.pn1 != ''" title="删除测点布设图" type="danger" icon="el-icon-delete" size="mini" @click="deleteProjectImg"></el-button>
						</div>
						<div class="upload-box" v-show="this.project_info.pn1 == ''">
							<p class="pTitle">点击上传图片</p>
							<uploadImg ref="pointImg" :configuration="parameterPoint" />
						</div>
						<img-sign ref="imgSign" v-show="this.project_info.pn1 != ''" :imgUrl="project_info.pn1"></img-sign>
					</div>
					<div class="bottom-but"><el-button type="primary" size="small" @click="projectSettings">确定</el-button></div>
				</div>
			</div>
		</div>
		<!-- 添加/编辑测点 -->
		<div class="edit-point Mask-box">
			<div class="edit-box main-box box-center">
				<p class="pop-ups-title">
					<span>{{ editPoint ? '编辑测点' : '添加测点' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('edit-point')"></span>
				</p>
				<div class="pop-ups-content">
					<p class="tips">
						<i>注意：</i>
						测点名称输入最多9个字符，且只能有字母，数字，‘+’或‘-’
						<!-- {{ editPoint ? '测点名称输入最多9个字符，且只能有字母，数字，‘+’或‘-’' : '测点名称输入最多6个字符，且只能有字母，数字，‘+’或‘-’' }} -->
					</p>
					<div class="cell">
						<span class="title">
							<i>*</i>
							测点名称
						</span>
						<div class="pointInput">
							<!-- <span v-show="!editPoint">ZD-</span> -->
							<!-- <span>ZD-</span> -->
							<!-- <el-input v-model="addPoint.name" :maxlength="editPoint ? '9' : '6'" show-word-limit placeholder="请输入测点名称"></el-input> -->
							<el-input v-model="addPoint.name" maxlength="9" show-word-limit placeholder="请输入测点名称"></el-input>
						</div>
					</div>
					<div class="cell">
						<span class="title">名称/位置</span>
						<el-input type="textarea" :rows="4" resize="none" v-model="addPoint.position" placeholder="请输入内容"></el-input>
					</div>
					<div class="cell">
						<span class="title">现场图片</span>
						<uploadImg ref="addPointImg" :configuration="addPointParameter" />
					</div>
					<div class="button-box">
						<el-button v-show="!editPoint" :disabled="addPoint.name == ''" type="primary" size="mini" @click="addPoints">添加</el-button>
						<el-button v-show="editPoint" :disabled="addPoint.name == ''" type="primary" size="mini" @click="pointEdit">修改</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 地图展示弹窗 -->
		<div class="map-pop-ups Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>地址</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('map-pop-ups')"></span>
				</p>
				<div class="pop-ups-content"><div id="view-map"></div></div>
			</div>
		</div>
		<!-- 地图选点 -->
		<div class="pick-point Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>经纬度</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('pick-point')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="input left">
						<el-input v-model="inputName" placeholder="可输入查询城市名"></el-input>
						<el-button type="primary" size="small" :disabled="inputName == ''" @click="inquirePoint">查询</el-button>
					</div>
					<div class="input right">
						经纬度：
						<el-input v-model="selectPoint" readonly placeholder="当前选点经纬度"></el-input>
						<el-button type="primary" size="small" @click="Assignment">确定</el-button>
					</div>
					<div id="pick-point"></div>
				</div>
			</div>
		</div>
		<!-- 报表设置弹窗 -->
		<setupReport ref="setupReport"></setupReport>
	</div>
</template>

<script>
import equipmentManagement from './equipmentManagement.js';
export default equipmentManagement;
</script>

<style scoped lang="scss">
@import './equipmentManagement.scss';
</style>
