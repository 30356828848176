<template>
	<!-- 设备列表 -->
	<div class="list-box">
		<vue-scroll :ops="ops" class="sc">
			<ul class="list-title">
				<li class="li-width-xs">序号</li>
				<li class="li-width-xl justify-left" v-show="parentObj.status == '企业'">项目</li>
				<li class="li-width-ls pointer sort" @click="dataSort($event, '仪器')">
					仪器编号
					<p>
						<!-- 未排序样式 -->
						<span class="icon iconfont icon-sort-up un-activation up"></span>
						<!-- 已排序样式 -->
						<span class="icon iconfont icon-sort-up-full activation up-full"></span>
						<!-- 未排序样式 -->
						<span class="icon iconfont icon-sort-down un-activation down"></span>
						<!-- 已排序样式 -->
						<span class="icon iconfont icon-sort-down-full activation down-full"></span>
					</p>
				</li>
				<li class="li-width-l justify-left pointer">测点编号</li>
				<li class="li-width-ls pointer">型号/参数</li>
				<li class="li-width-xs">状态</li>
				<li class="li-width-xs sort">
					<el-popover placement="end" width="160" v-model="visible">
						<div class="popover-box">
							<el-radio-group v-model="startAndStop" @change="statusFilter">
								<el-radio :label="2">全部</el-radio>
								<el-radio :label="0">已停用</el-radio>
								<el-radio :label="1">已启用</el-radio>
							</el-radio-group>
						</div>
						<div class="popover-but pointer" slot="reference">
							启停
							<span class="icon iconfont icon-filter"></span>
						</div>
					</el-popover>
				</li>
				<li class="li-width-ls pointer sort" @click="dataSort($event, '时间')">
					创建时间
					<p>
						<!-- 未排序样式 -->
						<span class="icon iconfont icon-sort-up un-activation up"></span>
						<!-- 已排序样式 -->
						<span class="icon iconfont icon-sort-up-full activation up-full"></span>
						<!-- 未排序样式 -->
						<span class="icon iconfont icon-sort-down un-activation down"></span>
						<!-- 已排序样式 -->
						<span class="icon iconfont icon-sort-down-full activation down-full"></span>
					</p>
				</li>
				<li class="li-width-s" v-show="judgment('authorityList', 281, parentObj) || judgment('authorityList', 282, parentObj)">操作</li>
			</ul>
			<div class="scroll-box list-scroll">
				<vue-scroll :ops="ops">
					<div class="ul-pop-ups">
						<ul class="list-content" v-for="(i, index) in list" :key="index">
							<li class="li-width-xs">{{ index + 1 }}</li>
							<li v-show="parentObj.status == '企业'" class="li-width-xl text-left text-overflow" :title="i.projectName"><p class="text-left text-overflow">{{ i.projectName | dataDetect }}</p></li>
							<li class="li-width-ls" :title="i.deviceNum">{{ i.deviceNum | dataDetect }}</li>
							<li class="li-width-l text-left" :title="i.pointName">{{ i.pointName | dataDetect }}</li>
							<li class="li-width-ls" :title="i.deviceParam">{{ i.deviceParam | dataDetect }}</li>
							<li :class="i.online == 1 ? 'li-width-xs green-t' : 'li-width-xs gray-t'">{{ i.online == 1 ? '在线' : '离线' }}</li>
							<li class="li-width-xs setups-box">
								<el-button v-if="i.state == '1'" type="primary" class="but" size="mini" @click="deactivate(i.id, i.deviceNum)" title="点击可停用">已启用</el-button>
								<el-button v-if="i.state != '1'" type="warning" class="but deactivate" size="mini" @click="open($event, 'pop-ups', '', i)" title="点击可启用">
									已停用
								</el-button>
							</li>
							<li class="li-width-ls" :title="i.time">{{ i.time | dataDetect }}</li>
							<li class="li-width-s iconBox" v-show="judgment('authorityList', 281, parentObj) || judgment('authorityList', 282, parentObj)">
								<span class="icon pointer el-icon-edit" v-show="judgment('authorityList', 281, parentObj)" @click="open($event, 'add-equipment', true, i)"></span>
								<span class="icon pointer el-icon-tickets blue-color" title="校准证书信息" @click="open($event, 'certificate', true, i)"></span>
								<span class="icon pointer el-icon-delete" v-show="judgment('authorityList', 282, parentObj)" @click="listDelete(i.id, i.deviceNum)"></span>
							</li>
						</ul>
					</div>
				</vue-scroll>
			</div>
		</vue-scroll>
		<!-- 弹窗 -->
		<div class="pop-ups" @click="close('', 'pop-ups')">
			<div class="main-pop equipment-pop-ups" @click="(e)=>{e.stopPropagation();}">
				<p class="pop-ups-title">
					<span>{{ popUpsName }}</span>
					<!-- 关闭按钮 -->
					<span class="pointer close el-icon-close" @click="close($event, 'pop-ups')"></span>
					<!-- 指示箭头 -->
					<!-- <span class="icon iconfont icon-cs-jt-xy-1-1 instructions"></span> -->
				</p>
				<div class="contentbox">
					<div class="list-title">
						<div class="text">
							<span v-show="activeName == 'project'">项目</span>
							<span v-show="activeName == 'point'">{{ projectName == '' ? '测点' : projectName }}</span>
						</div>
						<!-- 返回项目选择 -->
						<el-button v-show="activeName == 'point' && projectName != ''" class="back" type="primary" size="mini" @click="back">返回上一级</el-button>
					</div>
					<div class="pop-ups-list">
						<div class="scroll-box" v-show="activeName == 'project'">
							<vue-scroll>
								<div class="pop-ups-content">
									<ul>
										<li v-for="(item, ind) in project" :key="ind" class="pointer" @click="setChild(item)">
											<span>{{ item.name }}</span>
											<span class="icon iconfont el-icon-arrow-right"></span>
										</li>
									</ul>
								</div>
							</vue-scroll>
						</div>
						<div class="scroll-box" v-show="activeName == 'point'">
							<vue-scroll>
								<div class="pop-ups-content">
									<el-radio v-model="radio" :label="defaults"><el-input @input="setRadio" v-model="defaults" placeholder="新增测点编号"></el-input></el-radio>
									<el-radio-group v-model="radio">
										<el-radio v-for="(val, indexs) in point" :label="val" :key="indexs">{{ val }}</el-radio>
									</el-radio-group>
								</div>
							</vue-scroll>
						</div>
						<div class="bottom-box" v-show="activeName == 'point'">
							<el-button :disabled="radio == ''" type="primary" size="mini" @click="bindMeasuringPoint($event)">绑定</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	props: {
		list: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			activeName: 'project', //弹窗菜单选择
			popUpsName: '', //启用弹窗的名称（弹窗标题）
			project: [], //启用项目列表数据
			point: [], //启用设备列表数据
			companyId: null, //公司id
			projectIds: null, //启用弹窗选中的项目id
			projectName: '', //启用弹窗选中的项目名
			defaults: '', // 弹窗中输入框参数
			radio: '', //输入框单选框选中的选项
			radios: '', //单选框组选中的参数
			equipmentInfo: {}, // 点击启用的设备数据
			editEquipment: {
				numbering: '', // 编号
				type: '振动速度[V]cm/s', // 设备类型
				unit: 'cm/s', //单位
				enable: true, // 是否启用
				alarm: false // 是否离线报警
			}, // 设备编辑

			startAndStop: 2, //启停显示筛选条件
			visible: false, //Popover 弹出框变量
			parentObj: null, //父级this

			sortData: null, // 排序数据
			stateData: null, // 状态筛选数据

			ops: {
				bar: {
					onlyShowBarOnScroll: false, //不止只在滚动时显示 bar。
					keepShow: true //滚动条一直显示
				}
			} //滚动条设置
		};
	},
	created() {
		this.parentObj = this.$parent;
	},
	mounted() {
		this.activeName = 'project';
	},
	computed: {},
	methods: {
		// 默认单选框输入框选中
		setRadio(val) {
			this.radio = val;
			// // console.log(val);
			// // console.log(this.radio)
		},
		// 获取启用列表
		getEnableList() {
			var data = {
				companyId: this.$parent.enterprise_info.id,
				projectId: this.$parent.project_info.id
			};
			this.activeName = 'project';
			this.projectIds = '';
			this.companyId = '';
			this.point = '';
			this.projectName = '';
			this.axios.post('web2/mang/dmj/gubp', data).then(res => {
				this.project = [];
				if (res.status) {
					this.project = res.data;
					if (res.data.length == 1) {
						this.activeName = 'point';
						this.projectIds = res.data[0].id;
						this.companyId = res.data[0].cid;
						this.point = res.data[0].points;
						this.projectName = res.data[0].name;
					}
				}
			});
		},
		// 状态筛选
		statusFilter() {
			// console.log('筛选')
			$('.un-activation').show();
			$('.activation').hide();
			var data = {
				tp: 4,
			};
			this.$parent.currentPage = 1;
			if (this.startAndStop == 0) {
				data.st = 3;
			} else if (this.startAndStop == 1) {
				data.st = 2;
			} else if (this.startAndStop == 2) {
				data.st = 1;
			}
			this.stateData = data;
			this.sortData = null;
			this.$parent.getDataList(data);
		},
		// 数据排序点击事件
		dataSort(e, val) {
			var obj = $(e.currentTarget);
			obj.siblings()
				.find('.un-activation')
				.show();
			obj.siblings()
				.find('.activation')
				.hide();
			var upfull = obj.find('.up-full').is(':hidden');
			var data = {};
			this.$parent.currentPage = 1;
			if (upfull) {
				// 升序
				obj.find('.up').hide();
				obj.find('.up-full').show();
				obj.find('.down').show();
				obj.find('.down-full').hide();
				if (val == '仪器') {
					data.tp = 1;
					data.st = 1;
				} else if (val == '时间') {
					data.tp = 5;
					data.st = 1;
				}
			} else {
				// 降序
				obj.find('.up').show();
				obj.find('.up-full').hide();
				obj.find('.down').hide();
				obj.find('.down-full').show();
				if (val == '仪器') {
					data.tp = 1;
					data.st = 2;
				} else if (val == '状态') {
					data.tp = 3;
					data.st = 2;
				} else if (val == '时间') {
					data.tp = 5;
					data.st = 2;
				}
			}
			this.stateData = null;
			this.sortData = data;
			this.$parent.getDataList(data);
		},
		// 设置设备数据
		setChild(data) {
			// // console.log(data);
			this.point = [];
			this.activeName = 'point';
			this.projectName = data.name;
			this.point = data.points;
			this.projectIds = data.id;
			this.companyId = data.cid;
		},
		// 绑定设备
		bindMeasuringPoint(e) {
			// // console.log(this.defaults);
			// // console.log(this.point);
			var data = {
				id: this.equipmentInfo.id,
				projectId: this.projectIds,
				companyId: this.companyId,
				pointName: this.radio
			};
			this.axios.post('web2/mang/dmj/bdp', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '启用成功!'
					});
					this.close('', 'pop-ups');
					if (this.sortData != null) {
						this.$parent.getDataList(this.sortData);
					} else if (this.stateData != null) {
						this.$parent.getDataList(this.stateData);
					} else {
						this.$parent.getDataList();
					}
				}
			});
		},
		// 停用设备
		deactivate(id, deviceNum) {
			this.$confirm('此操作将停用<strong>'+ deviceNum +'</strong>，是否继续?', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('web2/mang/dmj/relived', { id }).then(res => {
						if (res.status) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '停用成功!'
							});
							if (this.sortData != null) {
								this.$parent.getDataList(this.sortData);
							} else if (this.stateData != null) {
								this.$parent.getDataList(this.stateData);
							} else {
								this.$parent.getDataList();
							}
						}
					});
				})
				.catch(() => {
					// this.$message({
					// 	type: 'info',
					// 	message: '已取消停用'
					// });
				});
		},
		// 列表删除
		listDelete(id, deviceNum) {
			this.$confirm('此操作将删除<strong>'+ deviceNum +'</strong>数据，是否继续?', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				customClass: 'icon-big',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				type: 'warning',
				center: true,
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios
						.post('web2/mang/dmq/dtd', {
							id
						})
						.then(res => {
							if (res.status) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '删除成功!'
								});
								if (this.sortData != null) {
									this.$parent.getDataList(this.sortData);
								} else if (this.stateData != null) {
									this.$parent.getDataList(this.stateData);
								} else {
									this.$parent.getDataList();
								}
							}
						});
				})
				.catch(() => {});
		},
		// 列表启用弹窗返回上一级
		back() {
			this.activeName = 'project';
			this.projectName = '';
		},
		// 打开弹窗(启用)
		open(e, obj, status, data = {}) {
			// console.log(e);
			if (obj == 'pop-ups') {
				var w = $('.left-box').width();
				var objW = $('.equipment-pop-ups').width();
				var left = e.pageX - objW - parseInt(objW / 3);
				$('.equipment-pop-ups').css({
					left: left + 'px'
				});
				$('.pop-ups').show();
				this.equipmentInfo = data;
				// // console.log(data);
				this.defaults = 'ZD-' + data.deviceNum.slice(5);
				this.popUpsName = data.deviceNum;
				this.radio = this.defaults;
				this.getEnableList();
			} else {
				if (obj == 'add-equipment') {
					// this.$parent.editEquipment = status;
					this.$parent.addEquipment.numbering = data.deviceNum;
					this.$parent.addEquipment.type = data.deviceParam;
					this.$parent.addEquipment.unit = data.unit;
					this.$parent.addEquipment.id = data.id;
					this.$parent.addEquipment.companyId = data.cid;
					this.$parent.addEquipment.pas = data.pas;
					// // console.log(this.$parent.addEquipment);
					this.$parent.open(obj, status);
				} else if (obj == 'certificate') {
					// this.$parent.certificate.serialNumber = data.deviceNum;
					// this.$parent.certificate.periodOfValidity = data.deviceParam;
					this.$parent.open(obj, status, data);
				} else {
					$('.' + obj).show();
				}

				//
			}
		},
		// 关闭弹窗
		close(e, obj) {
			if (obj == 'pop-ups') {
				this.defaults = '';
				this.radio = '';
				this.radios = '';
				this.activeName = 'project';
				this.projectName = '';
				this.point = [];
				this.projectIds = null;
				this.companyId = null;
			}
			$('.' + obj).hide();
		},
		// 弹窗中菜单选择
		handleClick(tab, event) {}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
// 数据列表
.list-box {
	width: 100%;
	height: calc(100% - 100px);
	position: relative;

	ul {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.text-left {
			text-align: left !important;
		}

		.justify-left {
			justify-content: flex-start !important;
		}

		li {
			padding: 5px 0;
			text-align: center;
			position: relative;

			::v-deep {
				.el-popover {
					top: 27px;
					left: 40px;
					width: 60px !important;
					min-width: 60px !important;

					.popover-box {
						height: 60px;
					}
				}

				.el-radio-group {
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
				}
			}

			.popover-but {
				width: 55px;
				// height: 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			p {
				height: 30px;
				display: flex;
				justify-content: space-around;
				flex-direction: column;
				margin-top: 4px;
				cursor: pointer;

				.up,
				.up-full {
					margin-bottom: -12.5px;
				}

				.down,
				.down-full {
					margin-top: -12.5px;
				}

				.activation {
					display: none;
				}
			}
		}
	}

	.list-title {
		width: calc(100% - 20px);
		margin: 0 10px;
		height: 45px;
		// background-color: #f2f6fc;
		// background-color: #87cbcb;
		background-color: #ebeef5;

		li {
			padding: 5px 0;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			font-size: 15px;
			font-weight: 600;
			color: #4c4e51;
			letter-spacing: 1px;
		}

		.sort {
			// color: #409eff;
			color: #008bcb;
		}
	}

	.pop-ups-title {
		// 关闭
		.close {
			font-size: 16px;
			color: #fff;
		}
	}
	
	
	.sc {
		// vue-scroll插件内部滚动区域样式更改。避免多个vue-scroll套用时高度不能按照外部盒子高度进行滚动
		::v-deep {
			.__view:first-child {
				height: 100%;
			}
		}
	}

	.scroll-box {
		height: calc(100% - 45px);

		// 滚动内容包裹盒子
		.ul-pop-ups {
			width: calc(100% - 20px);
			padding: 0 10px;
			position: relative;

			.list-content {
				// padding: 5px 0;
				padding: 5px 0;

				li {
					font-size: 16px;
					color: #000000;
					
					p{
						margin-top: 0;
					}
   
					.icon {
						font-size: 20px;
					}

					.blue-color {
						color: #409eff;
					}

					.el-icon-edit {
						color: #409eff;
					}

					.el-icon-delete {
						color: #e56c6c;
					}

					.el-icon-delete {
						margin-left: 15px;
					}
				}

				.setups-box {
					// position: relative;

					.but {
						width: 56px;
						height: 28px;
						line-height: 28px;
						text-align: center;
						padding: 0;
					}

					.deactivate {
						background-color: #ff905f;
					}
				}
			}

			.list-content:nth-child(even) {
				background-color: #f2f6fc;
			}
		}
	}

	.green-t {
		color: #67c23a !important;
	}

	.gray-t {
		color: #c0c4cc !important;
	}

	.li-width-xl {
		width: 170px;
	}

	.li-width-ls {
		width: 160px;
	}

	.li-width-l {
		width: 140px;
	}

	.li-width-s {
		width: 100px;
	}

	.li-width-xs {
		width: 60px;
	}

	.iconBox {
		display: flex;
		justify-content: center;
		align-items: center;

		span {
			margin-left: 17px;
			font-size: 20px !important;
		}

		span:first-child {
			margin-left: 0;
		}
	}
	// 列表中启用弹窗
	.pop-ups {
		width: 100%;
		height: 100%;
		// background-color: #000000;
		position: fixed;
		top: 0;
		left: 0;
		display: none;
		z-index: 1000;
		
		.main-pop {
			width: 300px;
			min-width: 220px;
			height: 450px;
			position: absolute;
			// top: 26px;
			// right: 81px;
			top: 105px;
			right: 0;
			bottom: 0;
			margin: auto 0;

			// 菜单样式更改
			::v-deep {
				.el-tabs__item {
					padding: 0;
					margin-left: 15px;
				}
				// 菜单选中样式更改
				.is-active {
					border-bottom: 2px solid #409eff;
				}
				// 去除菜单自带选中时的文字下划线
				.el-tabs__active-bar {
					display: none;
				}
			}

			.pop-ups-title {
				// 箭头指示
				.instructions {
					position: absolute;
					top: -18px;
					right: -19px;
					font-size: 18px !important;
					color: #00a7fa;
					transform: rotate(-25deg);
				}
			}

			.contentbox {
				height: calc(100% - 30px);
				// padding-top: 8px;
				border: thin solid #00a7fa;
				background-color: #ffffff;

				.list-title {
					padding: 0 10px 0px 10px;
					display: flex;
					justify-content: space-between;
					color: #00a7fa;
					font-size: 14px;
					border-bottom: thin solid #e5e5e5;
					margin: 0 0 8px 0;
					align-items: center;
					background-color: #f2f6fc;
				}

				.pop-ups-list {
					height: calc(100% - 55px);

					.scroll-box {
						height: calc(100% - 60px);

						.pop-ups-content {
							display: flex;
							flex-direction: column;

							::v-deep {
								// 输入框样式更改
								.el-input {
									width: 100%;
								}

								.el-input__inner {
									width: 100%;
									height: 25px !important;
									line-height: 25px;
									// border: none;
								}

								// 单选框组样式更改
								.el-radio-group {
									display: flex;
									flex-direction: column;
								}
							}

							// 单选框label样式更改
							.el-radio {
								height: 25px;
								line-height: 25px;
								margin: 0px 10px 8px 10px;
								display: flex;
								justify-content: flex-start;
								align-items: center;
							}

							ul {
								width: 100%;
								display: flex;
								flex-direction: column;
								justify-content: flex-start;

								li {
									width: calc(100% - 25px);
									margin-bottom: 10px;
									padding: 0 10px 0 15px;
									display: flex;
									justify-content: space-between;
									font-size: 13.5px;
									text-align: left;
								}
							}
						}
					}

					.bottom-box {
						margin: 10px 0;
						text-align: center;
					}
				}
			}
		}
	}
}
</style>
